import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { HStack } from '@elkaso-app/web-design';
import type { ProgressProps, TableProps } from 'antd';
import { Alert, Button, Image, Modal, Popover, Progress, Rate, Space, Table, Typography } from 'antd';
import cn from 'classnames';
import { TextCell } from 'components/text-cell';
import { useDisclosure } from 'hooks/use-disclosure';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import {
  HiOutlineBookOpen,
  HiOutlineChatAlt,
  HiOutlineMail,
  HiOutlinePhone,
  HiOutlineTrendingDown,
  HiOutlineTrendingUp,
  HiStar,
} from 'react-icons/hi';
import { RiEmotionHappyLine, RiEmotionUnhappyLine } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { formatPrice } from 'utils/format-price';
import { useScrollToSection } from '../hooks/use-scroll-to-section';
import { findSimilarRestaurants } from '../server/find-similar-restaurants';
import { RestaurantAnalysis, SimilarRestaurants } from '../types';
import { RestaurantsSlider } from './customers-slider';
import { PotentialSalesBar } from './potential-sales-bar';
import { RestaurantImage } from './restaurant-image';
import { RestaurantSalesChart } from './restaurant-sales-chart';
import { ReviewComponent } from './review-component';

const twoColors: ProgressProps['strokeColor'] = {
  '0%': '#FF0000',
  '100%': '#008000',
};

const twoColors1: ProgressProps['strokeColor'] = {
  '0%': '#87ceeb',
  '100%': '#008000',
};

const tableProps: TableProps = {
  size: 'small',
  scroll: { x: 'max-content' },
  pagination: {
    defaultPageSize: 5,
    size: 'default',
  },
};

interface RestaurantDataProps {
  restaurant?: RestaurantAnalysis;
}

export const RestaurantData = ({ restaurant }: RestaurantDataProps) => {
  const { supplierId } = useParams<{ supplierId: string }>();
  const [highPotentialCustomers, setHighPotentialCustomers] = useState<SimilarRestaurants[]>([]);
  const [similarCustomers, setSimilarCustomers] = useState<SimilarRestaurants[]>([]);
  const rightSideElementRef = useRef<HTMLDivElement>(null);
  const [rightSideWidth, setWidth] = useState<number>(0);
  const { scrollTo } = useScrollToSection();

  const itemsTheyOrderFromYouRef = useRef<HTMLDivElement>(null);
  const itemsTheyDoNotOrderFromYouRef = useRef<HTMLDivElement>(null);
  const itemsYouDoNotHaveRef = useRef<HTMLDivElement>(null);

  const { isOpen: isOpenSamModal, onClose: onCloseSamModal, onOpen: onOpenSamModal } = useDisclosure();
  const { isOpen: isOpenRfqModal, onClose: onCloseRfqModal, onOpen: onOpenRfqModal } = useDisclosure();
  const { isOpen: isOpenReviewsModal, onClose: onCloseReviewsModal, onOpen: onOpenReviewsModal } = useDisclosure();
  const { isOpen: isOpenMenuModal, onClose: onCloseMenuModal, onOpen: onOpenMenuModal } = useDisclosure();

  // Get similar restaurants
  useEffect(() => {
    if (!restaurant) return;
    const { highPotentialCustomers, similarCustomers } = findSimilarRestaurants(restaurant?.id, Number(supplierId));
    setHighPotentialCustomers(highPotentialCustomers);
    setSimilarCustomers(similarCustomers);
  }, [restaurant]);

  // Get right side width
  useEffect(() => {
    if (rightSideElementRef.current) {
      setWidth(rightSideElementRef.current.offsetWidth);
    }

    const handleResize = () => {
      if (rightSideElementRef.current) {
        setWidth(rightSideElementRef.current.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  interface ItemsTheyOrderFromYou {
    name: string;
    category: string;
    price: string;
    lastMonthSales: string;
    currentMonthSales: string;
    yearTodaySales: string;
    salesChangePercentage: number;
    frequency: number;
    averageMonthlyQuantity: number;
    unitOfMeasurement: string;
  }

  const itemsTheyOrderFromYouColumns: TableProps<ItemsTheyOrderFromYou>['columns'] = [
    {
      dataIndex: 'image',
      fixed: 'left',
      render: (text) => (
        <Image
          width={35}
          height={35}
          src={text}
          preview={false}
          fallback='https://placehold.co/50?text=img&font=roboto'
        />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      fixed: 'left',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      render: (text) => <TextCell value={text} />,
    },
    {
      title: 'Avg. Monthly QTY',
      dataIndex: 'averageMonthlyQuantity',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
    {
      title: 'Unit',
      dataIndex: 'unitOfMeasurement',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
    {
      title: 'Last Month Sales',
      dataIndex: 'lastMonthSales',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
    {
      title: 'Current Month Sales',
      dataIndex: 'currentMonthSales',
      render: (_, record) => (
        <div className='flex gap-2xs items-center'>
          <TextCell value={record.currentMonthSales} minWidth={70} />

          {record.salesChangePercentage > 0 && (
            <>
              <Popover content='Increase of sales compared to the previous month'>
                <div className='flex gap-2xs items-center cursor-default'>
                  <HiOutlineTrendingUp className='stroke-green-600' />
                  <p className='text-green-600'>{record.salesChangePercentage}%</p>
                </div>
              </Popover>
            </>
          )}

          {record.salesChangePercentage <= 0 && (
            <Popover content='Decrease of sales compared to the previous month'>
              <div className='flex gap-2xs items-center cursor-default'>
                <HiOutlineTrendingDown className='stroke-red-600' />
                <p className='text-red-600'>{record.salesChangePercentage}%</p>
              </div>
            </Popover>
          )}
        </div>
      ),
    },
    {
      title: 'YTD Sales',
      dataIndex: 'yearTodaySales',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
    {
      title: 'Frequency/Month',
      dataIndex: 'frequency',
      render: (text) => <TextCell value={text} />,
    },
    {
      render: (_, record) => (
        <div className='flex'>{record?.salesChangePercentage <= 0 && <Button size='small'>Recommend</Button>}</div>
      ),
    },
  ];

  const itemsTheyOrderFromYouData = useMemo<ItemsTheyOrderFromYou[]>(() => {
    if (!restaurant) return [];

    return restaurant?.itemsTheyOrderFromYou.map((i, index) => {
      return {
        key: index,
        ...i,
        price: formatPrice(i.price, restaurant.currency),
        lastMonthSales: formatPrice(i.lastMonthSales, restaurant.currency),
        currentMonthSales: formatPrice(i.currentMonthSales, restaurant.currency),
        yearTodaySales: formatPrice(i.yearTodaySales, restaurant.currency),
      };
    });
  }, [restaurant]);

  interface ItemsTheyDoNotOrderFromYou {
    key: number;
    name: string;
    category: string;
    price: string;
    unitOfMeasurement: string;
    averageMonthlyQuantity: number;
    expectedMonthlySales: {
      minSales: string;
      maxSales: string;
    };
  }

  const itemsTheyDoNotOrderFromYouColumns: TableProps<ItemsTheyDoNotOrderFromYou>['columns'] = [
    {
      dataIndex: 'image',
      fixed: 'left',
      render: (text) => (
        <Image
          width={35}
          height={35}
          src={text}
          preview={false}
          fallback='https://placehold.co/50?text=img&font=roboto'
        />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      fixed: 'left',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      render: (text) => <TextCell value={text} />,
    },
    {
      title: 'Avg. Monthly QTY',
      dataIndex: 'averageMonthlyQuantity',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
    {
      title: 'Unit',
      dataIndex: 'unitOfMeasurement',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
    {
      title: 'Expected Monthly Sales',
      dataIndex: 'expectedMonthlySales',
      render: (val) => (
        <div className='flex gap-sm'>
          <TextCell value={val?.minSales} />
          <p>~</p>
          <TextCell value={val?.maxSales} />
        </div>
      ),
    },
    {
      render: (_) => (
        <div className='flex'>
          <Button size='small'>Recommend</Button>
        </div>
      ),
    },
  ];

  const itemsTheyDoNotOrderFromYouData = useMemo<ItemsTheyDoNotOrderFromYou[]>(() => {
    if (!restaurant) return [];

    return restaurant?.itemsYouHaveButOrderFromOthers.map((i, index) => {
      return {
        key: index,
        ...i,
        price: formatPrice(i.price, restaurant.currency),
        expectedMonthlySales: {
          minSales: formatPrice(i.expectedMonthlySales.minSales, restaurant.currency),
          maxSales: formatPrice(i.expectedMonthlySales.maxSales, restaurant.currency),
        },
      };
    });
  }, [restaurant]);

  interface ItemsYouDoNotHave {
    key: number;
    name: string;
    category: string;
    averageMonthlyQuantity: number;
    unitOfMeasurement: string;
    expectedMonthlySales: {
      minSales: string;
      maxSales: string;
    };
  }

  const itemsYouDoNotHaveColumns: TableProps<ItemsYouDoNotHave>['columns'] = [
    {
      dataIndex: 'image',
      fixed: 'left',
      render: (text) => (
        <Image
          width={35}
          height={35}
          src={text}
          preview={false}
          fallback='https://placehold.co/50?text=img&font=roboto'
        />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      fixed: 'left',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      render: (text) => <TextCell value={text} />,
    },
    {
      title: 'Avg. Monthly QTY',
      dataIndex: 'averageMonthlyQuantity',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
    {
      title: 'Unit',
      dataIndex: 'unitOfMeasurement',
      render: (text) => <TextCell value={text} minWidth={100} />,
    },
    {
      title: 'Expected Monthly Sales',
      dataIndex: 'expectedMonthlySales',
      render: (val) => (
        <div className='flex gap-sm'>
          <TextCell value={val?.minSales} />
          <p>~</p>
          <TextCell value={val?.maxSales} />
        </div>
      ),
    },
  ];

  const itemsYouDoNotHaveData = useMemo<ItemsYouDoNotHave[]>(() => {
    if (!restaurant) return [];

    return restaurant?.itemsYouDoNotHave.map((i, index) => {
      return {
        key: index,
        ...i,
        expectedMonthlySales: {
          minSales: formatPrice(i.expectedMonthlySales.minSales, restaurant.currency),
          maxSales: formatPrice(i.expectedMonthlySales.maxSales, restaurant.currency),
        },
      };
    });
  }, [restaurant]);

  return (
    <div className='flex gap-md'>
      {/* Left Side */}
      <div className='flex flex-col gap-2xl w-[320px]'>
        <div className='flex gap-md items-center'>
          <RestaurantImage src={restaurant?.image} isNew={restaurant?.isNew} />

          <div>
            <Typography.Text
              style={{ minWidth: 200, maxWidth: 200 }}
              ellipsis={{
                suffix: '',
                tooltip: restaurant?.name,
              }}
              className='font-semibold text-xl !mb-0'>
              {restaurant?.name}
            </Typography.Text>

            <div className='mt-1'>
              <p className='text-default-500'>Category: {restaurant?.category}</p>
              <p className='text-default-500'>Cuisine: {restaurant?.cuisineType}</p>
            </div>
          </div>
        </div>

        <div className='flex items-center gap-3xs'>
          <Button className='flex-1' icon={<HiOutlinePhone />}>
            Call
          </Button>
          <Button className='flex-1' icon={<HiOutlineChatAlt />}>
            Message
          </Button>
          <Button className='flex-1' icon={<HiOutlineBookOpen />} onClick={onOpenMenuModal}>
            Menu
          </Button>
          {/* Menu Modal */}
          <Modal
            open={isOpenMenuModal}
            onCancel={onCloseMenuModal}
            width={800}
            title={
              <div className='modal-header'>
                <div className='modal-title'>Menu</div>
              </div>
            }
            footer={
              <div className='modal-footer'>
                <Button type='default' onClick={onCloseMenuModal}>
                  Close
                </Button>
              </div>
            }>
            {restaurant?.menuImages?.length ? (
              <div className='grid grid-cols-2 gap-xs'>
                <Image.PreviewGroup
                  preview={{
                    toolbarRender: (_, { transform: { scale }, actions: { onZoomOut, onZoomIn } }) => (
                      <Space size={12} className='toolbar-wrapper'>
                        <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                        <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                      </Space>
                    ),
                  }}>
                  {restaurant.menuImages.map((imageUrl, index) => (
                    <Image key={index} src={imageUrl} className='w-full object-cover' />
                  ))}
                </Image.PreviewGroup>
              </div>
            ) : (
              <p>No menu images available.</p>
            )}
          </Modal>
        </div>

        <div className='flex flex-col gap-1'>
          <div className='flex justify-between items-center'>
            <p className='text-lg'>Total Sales</p>
            <p className='text-xl font-semibold'>
              {formatPrice(restaurant?.salesVolume as number, restaurant?.currency)}
            </p>
          </div>

          <div className='flex justify-between items-center'>
            <p className='text-lg'>Potential Sales</p>
            <p className='text-xl font-semibold'>
              {formatPrice(restaurant?.potentialSalesVolume as number, restaurant?.currency)}
            </p>
          </div>
        </div>

        <PotentialSalesBar
          totalSales={restaurant?.salesVolume || 0}
          potentialSales={restaurant?.potentialSalesVolume || 0}
        />

        <div className='hidden'>
          <Progress percent={restaurant?.salesRange.percentage} strokeColor={twoColors} showInfo={false} />
          <div className='flex justify-between'>
            <p>{formatPrice(restaurant?.salesRange?.lowestOrder as number, restaurant?.currency)}</p>
            <p>1M Range</p>
            <p>{formatPrice(restaurant?.salesRange?.highestOrder as number, restaurant?.currency)}</p>
          </div>
        </div>

        <div className='flex flex-col gap-sm'>
          <div
            className={cn('text-2xl font-semibold text-white', 'p-md rounded-md text-center', {
              'bg-green-500': restaurant?.isCustomer,
              'bg-red-500': !restaurant?.isCustomer,
            })}>
            {restaurant?.isCustomer && (
              <div className='flex items-center justify-center gap-2'>
                <p>It&apos;s a Customer</p>
                <RiEmotionHappyLine color='white' />
              </div>
            )}
            {!restaurant?.isCustomer && (
              <div className='flex items-center justify-center gap-2'>
                <p>Not a Customer</p>
                <RiEmotionUnhappyLine color='white' />
              </div>
            )}
          </div>

          {!restaurant?.isCustomer && (
            <div className='flex items-center justify-between'>
              <p className='text-[16px]'>Generate RFQ</p>
              <Button onClick={onOpenRfqModal}>Generate</Button>

              <Modal
                open={isOpenRfqModal}
                onCancel={onCloseRfqModal}
                title={
                  <div className='modal-header'>
                    <div className='modal-title'>Generate RFQ</div>
                  </div>
                }
                footer={
                  <div className='modal-footer'>
                    <Button type='default' onClick={onCloseRfqModal}>
                      Close
                    </Button>
                  </div>
                }>
                Coming Soon...
              </Modal>
            </div>
          )}

          {!!restaurant?.isCustomer && (
            <div className='flex items-center justify-between'>
              <p className='text-[16px]'>Assigned SAM</p>
              <Button onClick={onOpenSamModal}>Details</Button>

              <Modal
                open={isOpenSamModal}
                onCancel={onCloseSamModal}
                title={
                  <div className='modal-header'>
                    <div className='modal-title'>Assigned SAM</div>
                  </div>
                }
                footer={
                  <div className='modal-footer'>
                    <Button type='default' onClick={onCloseSamModal}>
                      Close
                    </Button>
                  </div>
                }>
                {!restaurant.sam?.name && (
                  <Alert message="Assigned SAM isn't found." type='warning' showIcon className='mb-2xl' />
                )}

                <div>
                  <h1 className='font-semibold text-md'>{restaurant.sam?.name}</h1>

                  <div>
                    <div className='flex gap-md items-center mt-2'>
                      <HiOutlinePhone size={18} />
                      <Typography.Text className='text-[14px]' copyable>
                        {restaurant?.sam?.phone || 'Not available'}
                      </Typography.Text>
                    </div>
                    <div className='flex gap-md items-center mt-2'>
                      <HiOutlineMail size={18} />
                      <Typography.Text className='text-[14px]' copyable>
                        {restaurant?.sam?.email || 'Not available'}
                      </Typography.Text>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          )}
        </div>

        <div className='flex flex-col gap-xs'>
          <h1 className='font-semibold text-lg'>Google&apos;s Ratings</h1>
          <HStack>
            <Rate allowHalf disabled defaultValue={restaurant?.googleRating} />
            <p>{`(${restaurant?.googleRating})`}</p>
          </HStack>
          <div style={{ paddingBottom: '16px' }} />
          <h1 className='font-semibold text-lg'>Supplier&apos;s Ratings</h1>

          <div className='flex items-center gap-2'>
            <Rate allowHalf disabled defaultValue={restaurant?.averageRating} />
            <p>{`(${restaurant?.averageRating})`}</p>
            <Button className='ms-auto' onClick={onOpenReviewsModal}>
              Reviews
            </Button>

            <Modal
              open={isOpenReviewsModal}
              onCancel={onCloseReviewsModal}
              width={850}
              title={
                <div className='modal-header'>
                  <div className='modal-title'>Suppliers Review</div>
                </div>
              }
              footer={
                <div className='modal-footer'>
                  <Button type='default' onClick={onCloseReviewsModal}>
                    Close
                  </Button>
                </div>
              }>
              <div className='flex flex-col gap-2xl'>
                <div className='flex flex-col justify-center items-center gap-sm mt-md'>
                  <h1 className='font-semibold text-lg'>Overall Ratings</h1>
                  <div className='flex gap-2 items-center'>
                    <Rate
                      allowHalf
                      disabled
                      defaultValue={restaurant?.averageRating}
                      character={() => <HiStar size={35} />}
                    />
                    <p className='text-lg'>{`(${restaurant?.averageRating})`}</p>
                  </div>
                </div>

                <p className='border-b-1' />

                <div className='grid grid-cols-2 gap-y-[50px] gap-x-4xl'>
                  {restaurant?.suppliersRating.map((r, index) => (
                    <div key={index} className='flex gap-sm'>
                      <Image
                        width={35}
                        height={35}
                        preview={false}
                        fallback='https://placehold.co/50?text=img&font=roboto'
                      />
                      <div className='flex flex-col gap-2'>
                        <p className='font-semibold text-[16px]'>{r.supplierName}</p>
                        <ReviewComponent ratings={r.ratings} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Modal>
          </div>
        </div>

        <div className='flex flex-col gap-xs'>
          <h1 className='font-semibold text-lg'>Restaurant Priorities</h1>
          <div className='flex flex-col gap-sm'>
            <div className='flex items-center justify-between gap-md'>
              <p className='w-[80px] text-start'>Price</p>
              <Progress percent={(restaurant?.priceSensitivity.price as number) * 10} strokeColor={twoColors1} />
            </div>
            <div className='flex items-center justify-between gap-md'>
              <p className='w-[80px] text-start'>Quality</p>
              <Progress percent={(restaurant?.priceSensitivity.quality as number) * 10} strokeColor={twoColors1} />
            </div>
            <div className='flex items-center justify-between gap-md'>
              <p className='w-[80px] text-start'>Payment Terms</p>
              <Progress percent={(restaurant?.priceSensitivity.paymentTerms as number) * 10} strokeColor={twoColors1} />
            </div>
          </div>
        </div>

        <div className='flex flex-col gap-xs'>
          <h1 className='font-semibold text-lg'>Info</h1>
          <div className='flex flex-col gap-0 divide-y-1'>
            <div className='flex items-center justify-between gap-md py-2'>
              <p className='text-[14px]'>No. of locations:</p>
              <p>{restaurant?.locations.length}</p>
            </div>
            <div className='flex items-center justify-between gap-md py-2'>
              <p className='text-[14px]'>Items they use:</p>
              <p>{restaurant?.totalNumberOfRestaurantItems}</p>
            </div>
            <div className='flex items-center justify-between gap-md py-2'>
              <Typography.Link onClick={() => scrollTo(itemsTheyOrderFromYouRef)}>
                Items they order from you:
              </Typography.Link>
              <p>{restaurant?.totalNumberOfItemsTheyOrderFromYou}</p>
            </div>
            <div className='flex items-center justify-between gap-md py-2'>
              <Typography.Link onClick={() => scrollTo(itemsTheyDoNotOrderFromYouRef)}>
                Items you have but they don&apos;t order from you:
              </Typography.Link>
              <p>{restaurant?.totalNumberOfItemsYouHaveButOrderFromOthers}</p>
            </div>
            <div className='flex items-center justify-between gap-md py-2'>
              <Typography.Link onClick={() => scrollTo(itemsYouDoNotHaveRef)}>
                Items you don&apos;t have:
              </Typography.Link>
              <p>{restaurant?.totalNumberOfItemsYouDoNotHave}</p>
            </div>
          </div>
        </div>

        <div className='flex flex-col gap-lg'>
          <h1 className='font-semibold text-lg'>Contact</h1>
          <div>
            <h1 className='font-semibold text-md'>Manager</h1>

            <div className='flex flex-col divide-y-1'>
              <div className='flex gap-md items-center py-2'>
                <HiOutlinePhone size={18} />
                <Typography.Text className='text-[14px]' copyable>
                  {restaurant?.contacts.manager.phone}
                </Typography.Text>

                <Typography.Link className='ms-auto' href={`tel:${restaurant?.contacts.manager.phone}`} target='_blank'>
                  <Button size='small' className='w-[50px]'>
                    Call
                  </Button>
                </Typography.Link>
              </div>
              <div className='flex gap-md items-center py-2'>
                <FaWhatsapp size={18} />
                <Typography.Text className='text-[14px]' copyable>
                  {restaurant?.contacts.manager.phone}
                </Typography.Text>

                <Typography.Link
                  className='ms-auto'
                  href={`https://wa.me/${restaurant?.contacts.manager.phone}`}
                  target='_blank'>
                  <Button size='small' className='w-[50px]'>
                    Chat
                  </Button>
                </Typography.Link>
              </div>
              <div className='flex gap-md items-center py-2'>
                <HiOutlineMail size={18} />
                <Typography.Text
                  className='text-[14px]'
                  style={{ minWidth: 200, maxWidth: 200 }}
                  ellipsis={{
                    suffix: '',
                    tooltip: restaurant?.contacts.manager.email,
                  }}
                  copyable>
                  {restaurant?.contacts.manager.email}
                </Typography.Text>

                <Typography.Link
                  className='ms-auto min-w-[36px]'
                  href={`mailto:${restaurant?.contacts.manager.email}`}
                  target='_blank'>
                  <Button size='small' className='w-[50px]'>
                    Send
                  </Button>
                </Typography.Link>
              </div>
            </div>
          </div>

          <div>
            <h1 className='font-semibold text-md'>Sales</h1>
            <div className='flex flex-col divide-y-1'>
              <div className='flex gap-md items-center py-2'>
                <HiOutlinePhone size={18} />
                <Typography.Text className='text-[14px]' copyable>
                  {restaurant?.contacts.salesTeam.phone}
                </Typography.Text>

                <Typography.Link
                  className='ms-auto'
                  href={`tel:${restaurant?.contacts.salesTeam.phone}`}
                  target='_blank'>
                  <Button size='small' className='w-[50px]'>
                    Call
                  </Button>
                </Typography.Link>
              </div>
              <div className='flex gap-md items-center py-2'>
                <FaWhatsapp size={18} />
                <Typography.Text className='text-[14px]' copyable>
                  {restaurant?.contacts.salesTeam.phone}
                </Typography.Text>

                <Typography.Link
                  className='ms-auto'
                  href={`https://wa.me/${restaurant?.contacts.salesTeam.phone}`}
                  target='_blank'>
                  <Button size='small' className='w-[50px]'>
                    Chat
                  </Button>
                </Typography.Link>
              </div>
              <div className='flex gap-md items-center py-2'>
                <HiOutlineMail size={18} />
                <Typography.Text
                  className='text-[14px]'
                  style={{ minWidth: 200, maxWidth: 200 }}
                  ellipsis={{
                    suffix: '',
                    tooltip: restaurant?.contacts.salesTeam.email,
                  }}
                  copyable>
                  {restaurant?.contacts.salesTeam.email}
                </Typography.Text>

                <Typography.Link
                  className='ms-auto min-w-[36px]'
                  href={`mailto:${restaurant?.contacts.salesTeam.email}`}
                  target='_blank'>
                  <Button size='small' className='w-[50px]'>
                    Send
                  </Button>
                </Typography.Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Left Side */}

      {/* Divider */}
      <div className='border-[1px] border-default-200' />
      {/* Divider */}

      {/* Right Side */}
      <div
        className='flex flex-col gap-5xl flex-1'
        ref={rightSideElementRef}
        style={{ maxWidth: `calc(100% - ${320 + 34}px)` }}>
        <div>
          <RestaurantSalesChart chartData={restaurant?.chartData} width={rightSideWidth} />
        </div>

        <div className='flex flex-col gap-md' ref={itemsTheyOrderFromYouRef}>
          <h1 className='font-semibold text-xl'>Items They Order From You</h1>
          <Table columns={itemsTheyOrderFromYouColumns} dataSource={itemsTheyOrderFromYouData} {...tableProps} />
        </div>

        <div className='flex flex-col gap-md' ref={itemsTheyDoNotOrderFromYouRef}>
          <h1 className='font-semibold text-xl'>Items They Don&apos;t Order From You</h1>
          <Table
            columns={itemsTheyDoNotOrderFromYouColumns}
            dataSource={itemsTheyDoNotOrderFromYouData}
            {...tableProps}
          />
        </div>

        <div className='flex flex-col gap-md' ref={itemsYouDoNotHaveRef}>
          <h1 className='font-semibold text-xl'>Items You Don&apos;t Have</h1>
          <Table columns={itemsYouDoNotHaveColumns} dataSource={itemsYouDoNotHaveData} {...tableProps} />
        </div>

        {!!highPotentialCustomers.length && (
          <div className='flex flex-col gap-md'>
            <h1 className='font-semibold text-xl'>High Potential Customers</h1>
            <RestaurantsSlider restaurants={highPotentialCustomers} sliderWidth={rightSideWidth} />
          </div>
        )}

        {!!similarCustomers.length && (
          <div className='flex flex-col gap-md'>
            <h1 className='font-semibold text-xl'>Similar Customers</h1>
            <RestaurantsSlider restaurants={similarCustomers} sliderWidth={rightSideWidth} />
          </div>
        )}
      </div>
      {/* Right Side */}
    </div>
  );
};
