import items from '../database/items.json';
import orders from '../database/orders.json';
import restaurants from '../database/restaurants.json';
import suppliers from '../database/suppliers.json';
import { Item, Order, RestaurantAnalysis } from '../types';

/**
 * Function to get the actual item details from the items.json file
 * based on the item IDs returned by the previous function.
 * @param itemIds The array of item IDs to find.
 * @returns The list of items that match the given IDs.
 */
const getItemsFromIds = (itemIds: number[]): Item[] => {
  // Filter the items from the itemsData by checking if the item.id exists in itemIds
  return items.filter((item) => itemIds.includes(item.id));
};

/**
 * Get item details from the items.json file based on item ID.
 * @param itemId The ID of the item.
 * @returns The item object from items.json or undefined if not found.
 */
const getItemDetails = (itemId: number): Item | undefined => {
  return items.find((item) => item.id === itemId);
};

/**
 * Get the total value of an item from an order, considering its quantity.
 * @param itemId The ID of the item.
 * @param order The order object containing the item.
 * @returns The total value of the item in the order.
 */
const getItemTotalValue = (itemId: number, order: Order): number => {
  // Find the item in the order based on its ID
  const orderItem = order.items.find((orderItem) => orderItem.itemId === itemId);

  // Get the item's details (including price) from the items.json file
  const itemDetails = getItemDetails(itemId);

  // If the item exists in the order and we have its price, calculate total value based on quantity
  return orderItem && itemDetails ? orderItem.quantity * itemDetails.price : 0;
};

/**
 * Calculate the total sales for an item between two dates.
 * @param itemId The ID of the item.
 * @param orders The array of orders to check.
 * @param startDate The start date for the range.
 * @param endDate The end date for the range.
 * @returns The total sales value for the item.
 */
const calculateSalesForItem = (itemId: number, orders: Order[], startDate: Date, endDate: Date): number => {
  return orders
    .filter((order) => {
      const orderDate = new Date(order.createdAt);
      return orderDate >= startDate && orderDate <= endDate && order.items.some((o) => o.itemId === itemId);
    })
    .reduce((total, order) => total + getItemTotalValue(itemId, order), 0);
};

/**
 * Calculate the frequency of an item being ordered per month.
 * @param itemId The ID of the item.
 * @param orders The array of orders.
 * @param currentDate The current date.
 * @returns The average number of times the item was ordered per month.
 */
const calculateFrequencyForItem = (itemId: number, orders: Order[], currentDate: Date): number => {
  const firstOrderDate = orders.reduce((minDate, order) => {
    const orderDate = new Date(order.createdAt);
    if (order.items.some((o) => o.itemId === itemId)) {
      return orderDate < minDate ? orderDate : minDate;
    }
    return minDate;
  }, currentDate);

  const totalMonths = Math.max(
    1,
    (currentDate.getFullYear() - firstOrderDate.getFullYear()) * 12 +
      (currentDate.getMonth() - firstOrderDate.getMonth())
  );
  const totalOrders = orders.filter((order) => order.items.some((o) => o.itemId === itemId)).length;

  return parseFloat((totalOrders / totalMonths).toFixed(2));
};

/**
 * Calculate the expected monthly sales for an item based on how many times
 * the item got ordered from other suppliers. Returns a range with a margin.
 * If both minSales and maxSales are zero, assigns fake sales values. The minSales will be
 * adjusted to ensure it is always greater than the item's price.
 * @param itemId The ID of the item.
 * @param currentDate The current date for calculating monthly sales.
 * @returns An object with the range of expected monthly sales with a margin of 10% to 20%.
 */
const calculateExpectedMonthlySales = (itemId: number, currentDate: Date): { minSales: number; maxSales: number } => {
  // Step 1: Get all orders where the item was ordered
  const itemOrders = orders.filter((order) => order.items.some((o) => o.itemId === itemId));

  // Step 2: Calculate total sales value for the item from all these orders
  const totalSales = itemOrders.reduce((total, order) => total + getItemTotalValue(itemId, order), 0);

  // Step 3: Calculate how many months passed since the first order and the current date
  const firstOrderDate = itemOrders.reduce((minDate, order) => {
    const orderDate = new Date(order.createdAt);
    return orderDate < minDate ? orderDate : minDate;
  }, currentDate);

  const totalMonths = Math.max(
    1,
    (currentDate.getFullYear() - firstOrderDate.getFullYear()) * 12 +
      (currentDate.getMonth() - firstOrderDate.getMonth())
  );

  // Step 4: Calculate the expected monthly sales by dividing total sales by total months
  const expectedMonthlySales = totalSales / totalMonths;

  // Step 5: Apply a margin to calculate the range (10% to 20% margin)
  let minSales = expectedMonthlySales * 0.9; // 10% lower than expected sales
  let maxSales = expectedMonthlySales * 1.2; // 20% higher than expected sales

  // Get the item price from item details
  const itemDetails = getItemDetails(itemId); // Assuming getItemDetails returns the item object with price
  const itemPrice = itemDetails ? itemDetails.price : 0;

  // Step 6: If minSales and maxSales are both zero, assign fake sales values
  if (minSales === 0 && maxSales === 0) {
    minSales = Math.random() * 100 + 50; // Assign a random value between 50 and 150 for minSales
    maxSales = minSales * 1.2; // Assign maxSales as 20% higher than minSales
  }

  // Step 7: Ensure minSales is greater than the item price
  if (minSales < itemPrice) {
    minSales = itemPrice + Math.random() * 10; // Ensure minSales is slightly greater than the item price
    maxSales = minSales * 1.2; // Adjust maxSales accordingly
  }

  // Step 8: Return the range as an object, rounded to 2 decimal places
  return {
    minSales: Math.round(minSales * 100) / 100, // Rounded to 2 decimal places
    maxSales: Math.round(maxSales * 100) / 100, // Rounded to 2 decimal places
  };
};

/**
 * Calculate the average monthly quantity of an item based on orders from the past.
 * @param itemId The ID of the item.
 * @param orders The list of orders to calculate from.
 * @param currentDate The current date for the calculation.
 * @returns The average monthly quantity of the item.
 */
const calculateAverageMonthlyQuantity = (itemId: number, orders: Order[], currentDate: Date): number => {
  // Filter orders to include only those containing the specified item
  const itemOrders = orders.filter((order) => order.items.some((o) => o.itemId === itemId));

  // If no orders found, return 0
  if (itemOrders.length === 0) return 0;

  // Find the earliest order date for this item
  const firstOrderDate = itemOrders.reduce((minDate, order) => {
    const orderDate = new Date(order.createdAt);
    return orderDate < minDate ? orderDate : minDate;
  }, currentDate);

  // Calculate total months since the first order
  const totalMonths = Math.max(
    1,
    (currentDate.getFullYear() - firstOrderDate.getFullYear()) * 12 +
      (currentDate.getMonth() - firstOrderDate.getMonth())
  );

  // Calculate the total quantity of the item ordered
  const totalOrderedQuantity = itemOrders.reduce((total, order) => {
    const orderedItem = order.items.find((o) => o.itemId === itemId);
    return total + (orderedItem ? orderedItem.quantity : 0);
  }, 0);

  // Calculate and return the average monthly quantity
  const averageMonthlyQuantity = totalOrderedQuantity / totalMonths;
  return Math.round(averageMonthlyQuantity * 100) / 100; // Round to 2 decimal places
};

/**
 * Function to calculate the overall average rating for a restaurant from all suppliers.
 * @param suppliersRating An array of supplier ratings where each supplier has multiple ratings (e.g., Communication, Payments).
 * @returns The overall average rating across all suppliers, rounded to one decimal place.
 */
export const calculateOverallAverageRating = (
  suppliersRating: { supplierName: string; ratings: { label: string; ratingValue: number }[] }[]
): number => {
  // Step 1: Calculate the average rating for each supplier
  const supplierAverages = suppliersRating.map((supplier) => {
    const totalSupplierRatings = supplier.ratings.reduce((total, rating) => total + rating.ratingValue, 0);
    const supplierAverage = totalSupplierRatings / supplier.ratings.length;
    return supplierAverage;
  });

  // Step 2: Calculate the overall average rating by summing up all supplier averages
  const totalSupplierAverage = supplierAverages.reduce((total, avg) => total + avg, 0);

  // Step 3: Return the overall average, which is the sum of supplier averages divided by the number of suppliers
  const overallAverageRating = totalSupplierAverage / suppliersRating.length;

  // Return the overall average rating, rounded to 1 decimal place
  return Math.round(overallAverageRating * 10) / 10;
};

/**
 * Get restaurant details by ID.
 * @param restaurantId The ID of the restaurant to retrieve.
 * @returns The restaurant details or an error message if not found.
 */
export const getRestaurantById = (restaurantId: number, supplierId: number): RestaurantAnalysis | string => {
  const restaurant = restaurants.find((r) => r.id === restaurantId);
  const supplier = suppliers.find((s) => s.id === supplierId);

  if (!supplier) {
    // Return a detailed error message if supplier is not found
    throw Error(`Supplier with ID "${supplierId}" not found.`);
  }

  if (!restaurant) {
    // Return a detailed error message if restaurant is not found
    throw Error(`Restaurant with ID "${restaurantId}" not found.`);
  }

  const ordersFromSupplier = orders.filter((o) => o.supplierId === supplier.id && o.restaurantId === restaurant.id);
  const ordersByRestaurant = orders.filter((o) => o.restaurantId === restaurant.id);

  /*
   * ===
   * Is Customer
   * ===
   */
  const isCustomer = ((): boolean => {
    // Check if there's any order where both supplierId and restaurantId match
    const customerOrderExists = orders.some(
      (order) => order.supplierId === supplierId && order.restaurantId === restaurantId
    );

    return customerOrderExists;
  })();

  /*
   * ===
   * Sales Volume
   * ===
   */
  const salesVolume = (() => {
    let value = 0;

    ordersFromSupplier.map((o) => {
      value += o.totalValue;
    });

    return value;
  })();

  /*
   * ===
   * Potential Sales Volume
   * ===
   */
  const potentialSalesVolume = (() => {
    let value = 0;

    ordersByRestaurant.map((o) => {
      value += o.totalValue;
    });

    return value;
  })();

  /*
   * ===
   * Sales Range
   * ===
   */
  const salesRange = (() => {
    // Ensure the ordersFromSupplier array is not empty before reducing
    const lowestOrder = ordersFromSupplier.length
      ? ordersFromSupplier.reduce((minOrder, currentOrder) => {
          return currentOrder.totalValue < minOrder.totalValue ? currentOrder : minOrder;
        }, ordersFromSupplier[0]) // Set initial value to the first order
      : null; // Handle the case where there are no orders

    // Find the highest order value
    const highestOrder = ordersFromSupplier.length
      ? ordersFromSupplier.reduce((maxOrder, currentOrder) => {
          return currentOrder.totalValue > maxOrder.totalValue ? currentOrder : maxOrder;
        }, ordersFromSupplier[0]) // Set initial value to the first order
      : null; // Handle the case where there are no orders

    // Find the latest order by createdAt
    const latestOrder = ordersFromSupplier.length
      ? ordersFromSupplier.reduce((latest, currentOrder) => {
          return new Date(currentOrder.createdAt) > new Date(latest.createdAt) ? currentOrder : latest;
        }, ordersFromSupplier[0]) // Set initial value to the first order
      : null; // Handle the case where there are no orders

    const lowestOrderValue = lowestOrder?.totalValue || 0;
    const latestOrderValue = latestOrder?.totalValue || 0;
    const highestOrderValue = highestOrder?.totalValue || 0;

    return {
      lowestOrder: lowestOrderValue,
      latestOrder: latestOrderValue,
      highestOrder: highestOrderValue,
      percentage: highestOrderValue > 0 ? (latestOrderValue / highestOrderValue) * 100 : 0,
    };
  })();

  /*
   * ===
   * SAM
   * ===
   */
  const sam = (() => {
    const assignedSAM = supplier.SAMs.find((sam) => sam.assignedTo === restaurant.id);

    if (!assignedSAM) {
      console.warn(`No SAM assigned to restaurant with ID ${restaurant.name} for supplier ID ${supplier.name}.`);
    }

    return assignedSAM;
  })();

  /*
   * ===
   * Is New
   * ===
   */
  const isNew = ((): boolean => {
    const createdDate = new Date(restaurant.createdAt);
    const currentDate = new Date();

    // Calculate the date 3 months ago
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(currentDate.getMonth() - 3);

    // Check if the createdDate is within the last 3 months
    return createdDate >= threeMonthsAgo && createdDate <= currentDate;
  })();

  /*
   * ===
   * Average Raging
   * ===
   */
  const averageRating = ((): number => {
    const { suppliersRating } = restaurant;
    return calculateOverallAverageRating(suppliersRating);
  })();

  /*
   * ===
   * Sales Chart 1 YTD
   * ===
   */
  const chartData = (() => {
    const currentDate = new Date();
    const lastYear = new Date();
    lastYear.setFullYear(currentDate.getFullYear() - 1);

    // Initialize an array to represent the last 12 months
    const monthLabels = [];
    for (let i = 0; i < 12; i++) {
      const date = new Date();
      date.setMonth(currentDate.getMonth() - i);
      monthLabels.push(date?.toLocaleString('default', { month: 'short' }));
    }
    monthLabels.reverse(); // Ensure the months are in chronological order

    // Initialize an object to store sales for each of the last 12 months
    const salesData = monthLabels.reduce((acc, month) => {
      acc[month] = 0;
      return acc;
    }, {} as Record<string, number>);

    // Step 1: Filter orders for the given restaurantId and from the past year
    const restaurantOrders = orders.filter((order) => {
      const orderDate = new Date(order.createdAt);
      return (
        order.restaurantId === restaurantId &&
        order.supplierId === supplierId &&
        orderDate >= lastYear &&
        orderDate <= currentDate
      );
    });

    // Step 2: Sum total sales for each month
    restaurantOrders.forEach((order) => {
      const orderDate = new Date(order.createdAt);
      const month = orderDate?.toLocaleString('default', { month: 'short' });

      // Accumulate total sales for the respective month
      if (salesData[month] !== undefined) {
        salesData[month] += order.totalValue;
      }
    });

    // Step 3: Convert the salesData object to the required array format
    const result = Object.keys(salesData).map((month) => ({
      month,
      sales: salesData[month],
    }));

    return result;
  })();

  /*
   * ===
   * Total number of restaurant items
   * ===
   */
  const totalNumberOfRestaurantItems = (() => {
    return restaurant.items.length;
  })();

  /*
   * ===
   * Items they order from you
   * ===
   */
  const itemsTheyOrderFromYou = (() => {
    // Step 1: Gather all items from the filtered orders into one big array
    const allItems = ordersFromSupplier.flatMap((order) => order.items);

    // Step 2: Create a map to track total quantities of each item
    const itemQuantities = allItems.reduce((acc, item) => {
      if (!acc[item.itemId]) {
        acc[item.itemId] = 0;
      }
      acc[item.itemId] += item.quantity; // Add up quantities for the same itemId
      return acc;
    }, {} as Record<number, number>); // Record<number, number> to store { itemId: quantity }

    // Step 3: Get unique items data
    const uniqueItemsData = getItemsFromIds(Object.keys(itemQuantities).map(Number));

    // Step 4: Add extra calculations to each item
    const currentDate = new Date();
    const lastMonth = new Date();
    lastMonth.setMonth(currentDate.getMonth() - 1);
    const startOfYear = new Date(currentDate.getFullYear(), 0, 1);

    // Get the start of the current month
    const startOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

    const updatedItems = uniqueItemsData.map((item) => {
      const lastMonthSales = calculateSalesForItem(item.id, ordersFromSupplier, lastMonth, startOfCurrentMonth); // Last month sales
      const currentMonthSales = calculateSalesForItem(item.id, ordersFromSupplier, startOfCurrentMonth, currentDate); // Current month sales
      const yearTodaySales = calculateSalesForItem(item.id, ordersFromSupplier, startOfYear, currentDate); // Year-to-date sales
      const frequency = calculateFrequencyForItem(item.id, ordersFromSupplier, currentDate); // Frequency of orders
      const averageMonthlyQuantity = calculateAverageMonthlyQuantity(item.id, ordersFromSupplier, currentDate);

      // Calculate the percentage change between last month and current month sales
      let salesChangePercentage = 0;
      if (lastMonthSales > 0) {
        salesChangePercentage = ((currentMonthSales - lastMonthSales) / lastMonthSales) * 100;
      } else if (currentMonthSales > 0) {
        // Handle the case where last month sales are 0 but current month sales exist
        salesChangePercentage = 100;
      }

      return {
        ...item,
        quantity: itemQuantities[item.id], // Total quantity ordered for this item
        lastMonthSales, // Sales for last month
        currentMonthSales, // Sales for the current month
        yearTodaySales, // Sales from the start of the year until today
        frequency, // Frequency of orders
        salesChangePercentage: Math.round(salesChangePercentage * 100) / 100, // Rounded to 2 decimal places
        averageMonthlyQuantity, // Average monthly quantity of the item
      };
    });

    return updatedItems;
  })();

  /*
   * ===
   * Total number of Items they order from you
   * ===
   */
  const totalNumberOfItemsTheyOrderFromYou = (() => {
    return itemsTheyOrderFromYou.length;
  })();

  /*
   * ===
   * Items you don’t have
   * ===
   */
  const itemsYouDoNotHave = (() => {
    // Step 1: Convert supplier items to a Set for faster lookup
    const supplierItemsSet = new Set(supplier.items);

    // Step 2: Filter restaurant items to find those that the supplier doesn't have
    const missingItems = restaurant.items.filter((item) => !supplierItemsSet.has(item));

    // Step 3: Get missing items data
    const missingItemsData = getItemsFromIds(missingItems);

    // Step 4: Add extra calculations to each item
    const currentDate = new Date();

    const updatedItems = missingItemsData.map((item) => {
      const expectedMonthlySales = calculateExpectedMonthlySales(item.id, currentDate);
      const averageMonthlyQuantity = calculateAverageMonthlyQuantity(item.id, ordersByRestaurant, currentDate);

      return {
        ...item,
        expectedMonthlySales, // Total value of how many times the item got ordered
        category: item.category, // Adding category from items.json
        averageMonthlyQuantity,
      };
    });

    return updatedItems;
  })();

  /*
   * ===
   * Total number of items you don’t have
   * ===
   */
  const totalNumberOfItemsYouDoNotHave = (() => {
    return itemsYouDoNotHave.length;
  })();

  /*
   * ===
   * Items you have but they order from others
   * ===
   */
  const itemsYouHaveButOrderFromOthers = (() => {
    // Step 1: Convert supplier items to a Set for fast lookup
    const supplierItemsSet = new Set(supplier.items);

    // Step 2: Find all items that the restaurant ordered from other suppliers
    const otherSupplierOrders = orders.filter(
      (order) => order.restaurantId === restaurant.id && order.supplierId !== supplier.id
    );

    const otherSupplierItems = otherSupplierOrders.flatMap((order) => order.items);

    // Step 3: Create a map to track total quantities of matching items
    const matchingItemQuantities = otherSupplierItems.reduce((acc, item) => {
      if (supplierItemsSet.has(item.itemId)) {
        if (!acc[item.itemId]) {
          acc[item.itemId] = 0;
        }
        acc[item.itemId] += item.quantity; // Sum the quantities for each item
      }
      return acc;
    }, {} as Record<number, number>); // Map itemId to total quantity

    // Step 4: Get matching items data
    const matchingItemsData = getItemsFromIds(Object.keys(matchingItemQuantities).map(Number));

    // Step 5: Add extra calculations to each item
    const currentDate = new Date();

    const updatedItems = matchingItemsData.map((item) => {
      const expectedMonthlySales = calculateExpectedMonthlySales(item.id, currentDate);
      const averageMonthlyQuantity = calculateAverageMonthlyQuantity(item.id, ordersByRestaurant, currentDate);

      return {
        ...item,
        quantity: matchingItemQuantities[item.id], // Total quantity ordered from other suppliers
        expectedMonthlySales, // Total value of how many times the item got ordered
        category: item.category, // Adding category from items.json
        averageMonthlyQuantity,
      };
    });

    return updatedItems;
  })();

  /*
   * ===
   * Total number of items you have but they order from others
   * ===
   */
  const totalNumberOfItemsYouHaveButOrderFromOthers = (() => {
    return itemsYouHaveButOrderFromOthers.length;
  })();

  // Optionally, apply analysis or calculations (depending on what RestaurantAnalysis type involves)
  const restaurantAnalysis: RestaurantAnalysis = {
    ...restaurant,
    // Add custom fields or calculations based on RestaurantAnalysis definition
    isCustomer,
    salesVolume,
    potentialSalesVolume,
    salesRange,
    sam,
    isNew,
    averageRating,
    chartData,
    totalNumberOfRestaurantItems,
    totalNumberOfItemsTheyOrderFromYou,
    totalNumberOfItemsYouDoNotHave,
    totalNumberOfItemsYouHaveButOrderFromOthers,
    itemsTheyOrderFromYou,
    itemsYouDoNotHave,
    itemsYouHaveButOrderFromOthers,
  };

  return restaurantAnalysis;
};
