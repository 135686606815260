import { AutoComplete, Checkbox, Image, Tag } from 'antd';
import _debounce from 'lodash/debounce';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { searchRestaurants } from '../server/serach-engine/search-restaurant-engine';
import { RestaurantSearch } from '../types';
import { FilterType } from '../types/filter.type';

interface SearchInputProps {
  defaultValue?: string;
}

export const SearchInput = ({ defaultValue }: SearchInputProps) => {
  const navigate = useNavigate();
  const { supplierId, restaurantId } = useParams<{ supplierId: string; restaurantId: string }>();
  const [options, setOptions] = useState<RestaurantSearch[]>([]);
  const [filterTypes, setFilterTypes] = useState<FilterType[]>([FilterType.Name]); // 'name' is always selected by default

  const debouncedFetchData = useCallback(
    _debounce(async (searchText: string) => {
      try {
        const result = await searchRestaurants(searchText, Number(supplierId), filterTypes);

        const newOptions: RestaurantSearch[] = result.map((restaurant: RestaurantSearch) => restaurant);

        setOptions(newOptions);
      } catch (error) {
        console.error('Error fetching restaurants:', error);
      }
    }, 500),
    [filterTypes]
  );

  const handleSearch = (searchText: string) => {
    if (searchText) {
      debouncedFetchData(searchText);
    } else {
      setOptions([]);
    }
  };

  const handleSelect = (restaurantId: number) => {
    navigate(`/restaurant-analysis/${supplierId}/${restaurantId}`, { replace: true });
  };

  const onClear = () => {
    navigate(`/restaurant-analysis/${supplierId}`, { replace: true });
  };

  const renderOptionLabel = (restaurant: RestaurantSearch) => (
    <div className='flex items-center gap-sm'>
      <Image
        src={restaurant.image}
        preview={false}
        width={60}
        fallback='https://placehold.co/400?text=KASO&font=roboto'
      />

      <div className='flex flex-col gap-[1px]'>
        <div className='flex gap-2 items-center'>
          <h2 className='font-semibold text-md'>{restaurant.name}</h2>
          {restaurant.isNew && (
            <Tag color='#2f54eb' bordered={false} className='text-[10px] leading-[15px] px-[5px]'>
              New
            </Tag>
          )}
        </div>

        <p className='text-default-500 text-[14px]'>Type: {restaurant.cuisineType}</p>
        <div className='flex gap-md text-default-500 text-[14px]'>
          <div>
            <p>Locations: {restaurant.locations.length}</p>
          </div>
          <div className='flex items-center gap-2'>
            <p>Is a customer:</p>
            <Tag
              color={restaurant.isCustomer ? 'green' : 'red'}
              bordered={true}
              className='text-[10px] leading-[15px] px-[5px]'>
              {restaurant.isCustomer ? 'Yes' : 'No'}
            </Tag>
          </div>
        </div>
      </div>
    </div>
  );

  const handleFilterChange = (checkedValues: (string | FilterType)[]) => {
    setFilterTypes([FilterType.Name, ...(checkedValues.filter((value) => value !== FilterType.Name) as FilterType[])]);
  };

  if (restaurantId) {
    return (
      <div className='flex flex-col gap-xs'>
        <h1 className='text-xl'>Find a Restaurant</h1>
        <div className='flex gap-md'>
          <Checkbox value={FilterType.Name} checked disabled>
            Search by Name
          </Checkbox>
          <Checkbox.Group
            value={filterTypes}
            onChange={(values) => handleFilterChange(values as (string | FilterType)[])}>
            <Checkbox value={FilterType.Location}>Search by Location</Checkbox>
            <Checkbox value={FilterType.Product}>Search by Product</Checkbox>
            <Checkbox value={FilterType.Cuisine}>Search by Cuisine</Checkbox>
          </Checkbox.Group>
        </div>
        <AutoComplete
          style={{
            width: 400,
            height: 48,
          }}
          options={options.map((option) => ({
            key: option.id,
            value: option.name,
            label: renderOptionLabel(option),
          }))}
          defaultValue={defaultValue}
          onSearch={handleSearch}
          onSelect={(_, option) => handleSelect(option.key)}
          placeholder='Write a restaurant name'
          allowClear
          onClear={onClear}
        />
      </div>
    );
  }

  return (
    <div className='flex flex-col gap-xs'>
      <h1 className='text-xl'>Find a Restaurant</h1>
      <div className='flex gap-md'>
        <Checkbox value={FilterType.Name} checked disabled>
          Search by Name
        </Checkbox>
        <Checkbox.Group
          value={filterTypes}
          onChange={(values) => handleFilterChange(values as (string | FilterType)[])}>
          <Checkbox value={FilterType.Location}>Search by Location</Checkbox>
          <Checkbox value={FilterType.Product}>Search by Product</Checkbox>
          <Checkbox value={FilterType.Cuisine}>Search by Cuisine</Checkbox>
        </Checkbox.Group>
      </div>
      <AutoComplete
        style={{
          width: 700,
          height: 60,
        }}
        options={options.map((option) => ({
          key: option.id,
          value: option.name,
          label: renderOptionLabel(option),
        }))}
        defaultValue={defaultValue}
        onSearch={handleSearch}
        onSelect={(_, option) => handleSelect(option.key)}
        placeholder='Write a restaurant name'
        allowClear
        onClear={onClear}
      />
    </div>
  );
};
