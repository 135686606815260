import { Image, Typography } from 'antd';
import cn from 'classnames';
import { FullPageSpinner } from 'components/full-page-spinner';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BrandLogo } from '../components/brand-logo';
import { RestaurantData } from '../components/restaurant-data';
import { SearchInput } from '../components/search-input';
import { getRestaurantById } from '../server/get-restaurant-by-id';
import { getSupplierDetails } from '../server/get-supplier-details';
import { RestaurantAnalysis } from '../types';
import { consoleLogs } from '../utils/console-logs';

export const RestaurantDetailsScreen = () => {
  const { supplierId, restaurantId } = useParams<{ supplierId: string; restaurantId: string }>();
  const [restaurant, setRestaurant] = useState<RestaurantAnalysis>();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    consoleLogs();
  }, []);

  const supplierDetails = useMemo(() => {
    return getSupplierDetails(Number(supplierId));
  }, [supplierId]);

  useEffect(() => {
    if (!restaurantId) {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);

      const result = getRestaurantById(Number(restaurantId), Number(supplierId));

      if (typeof result !== 'string') {
        setRestaurant(result);
      }
    } catch (error) {
      alert(error as string);
      navigate(`/restaurant-analysis/${supplierId}`, { replace: true });
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [restaurantId]);

  if (loading) return <FullPageSpinner />;
  return (
    <div className='container mx-auto pb-[200px]'>
      {/* Logo */}
      <div className='flex items-center justify-between h-[80px]'>
        <BrandLogo />

        <div className='flex items-center gap-xs'>
          <Image
            width={35}
            height={35}
            src={supplierDetails?.image}
            preview={false}
            fallback='https://placehold.co/50?text=img&font=roboto'
          />

          <Typography.Text
            style={{ maxWidth: 200 }}
            ellipsis={{
              suffix: '',
              tooltip: supplierDetails?.name,
            }}
            className='font-semibold text-lg !mb-0'>
            {supplierDetails?.name}
          </Typography.Text>
        </div>
      </div>

      {/* Search */}
      <div className={cn('flex flex-col items-center', { 'mt-[50px]': !restaurantId })}>
        {!restaurantId && (
          <p className='font-bold text-[80px] mb-5xl text-start max-w-[1000px] text-secondary-500'>
            Easily find & analyze restaurants across UAE
          </p>
        )}

        <SearchInput defaultValue={restaurant?.name} />
      </div>

      {/* Data */}
      <div className={cn('mt-[60px]', { hidden: !restaurantId })}>
        <RestaurantData restaurant={restaurant} />
      </div>
    </div>
  );
};
