import { Rate, Tag, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { Restaurant, SimilarRestaurants } from '../types';
import { RestaurantImage } from './restaurant-image';

interface CustomerSliderProps {
  restaurants: SimilarRestaurants[];
  sliderWidth: number;
}

export const RestaurantsSlider = ({ restaurants, sliderWidth }: CustomerSliderProps) => {
  const { supplierId } = useParams<{ supplierId: string }>();
  const navigate = useNavigate();

  const onRestaurantClick = (restaurant: Restaurant) => {
    navigate(`/restaurant-analysis/${supplierId}/${restaurant.id}`, { replace: true });
  };

  return (
    <div className='flex items-start gap-md overflow-x-auto' style={{ width: sliderWidth }}>
      {restaurants?.map((r) => (
        <div
          key={r.id}
          className='border-1 rounded-md p-md cursor-pointer min-w-[320px]'
          onClick={() => onRestaurantClick(r)}>
          <div className='flex gap-md items-center'>
            <RestaurantImage src={r?.image} isNew={r?.isNew} />

            <div className='flex flex-col'>
              <Typography.Text
                style={{ minWidth: 200, maxWidth: 200 }}
                ellipsis={{
                  suffix: '',
                  tooltip: r?.name,
                }}
                className='font-semibold text-md !mb-0'>
                {r?.name}
              </Typography.Text>

              <div className='mt-1'>
                <p className='text-default-500'>Category: {r?.category}</p>
                <p className='text-default-500'>Cuisine: {r?.cuisineType}</p>
              </div>
            </div>
          </div>

          <div className='mt-md'>
            <div className='flex flex-col gap-xs'>
              <div className='flex items-center gap-2'>
                <Rate allowHalf disabled defaultValue={r?.averageRating} />
                <p>{`(${r?.averageRating})`}</p>
              </div>

              <div className='flex gap-2'>
                <p className='text-[14px]'>Is a customer:</p>
                <Tag color={r.isCustomer ? 'green' : 'red'} bordered={true} className='text-[14px]'>
                  {r.isCustomer ? 'Yes' : 'No'}
                </Tag>
              </div>

              <div className='flex gap-2'>
                <p className='text-[14px]'>No. of locations:</p>
                <p className='text-[14px]'>{r.locations.length}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
